import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a2c8868"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-8 OpeningOpacityText flex-col justify-evenly pb-5 OpeningResults sm:text-sm" }
const _hoisted_2 = { class: "flex flex-col justify-center" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = {
  key: 0,
  class: "flex justify-center mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgContainer = _resolveComponent("SvgContainer")!
  const _component_PopUpLight = _resolveComponent("PopUpLight")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "fav-icon p-2 cursor-pointer",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, [
      _createVNode(_component_SvgContainer, { name: "shareIcon" })
    ]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.showUrl)
          ? (_openBlock(), _createBlock(_component_PopUpLight, {
              key: 0,
              onClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showUrl = false))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.shareUrl), 1),
                    (_ctx.showCopied)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("copied_exclamation")), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}