<template>
  <main>
    <div class="flex justify-around items-center wrapperPlayers">
      <div
        v-if="!isSoccer()"
        ref="locatePlayers"
        class="flex flex-col items-center"
      >
        <!-- <h2 v-if="showTitle === true" class="mb-6 sm:hidden">
          {{ $t("locate_players").toUpperCase() }}
        </h2> -->
        <div
          v-if="!enabled && mode != playerMode.RESULTS"
          class="error_color mb-4"
        >
          <p class="">{{ $t("cancelled") }}</p>
        </div>
        <div class="flex playersContainer">
          <div class="borderBoxPayment blankBoxLeft"></div>
          <div
            v-click-outside="cancelMoving"
            class="flex wrapperBoxPayment flex-wrap"
          >
            <div
              v-for="(player, index) in getPlayers()"
              :key="player.position"
              v-on:click="enabled ? selectPlayer(player) : null"
              class="borderBoxPayment boxPayment flex flex-col justify-center items-center relative overflow-hidden"
              :class="{
                borderTest: index == 0 || index == 2,
                selectPlayerBorder: player.selected,
              }"
            >
              <div
                class="imageBoxPlayers relative mb-3 sm:mb-1 sm:mt-2 sm:h-56"
              >
                <div
                  v-if="isMatch && hasLevel(player)"
                  class="absolute scoreUsers"
                >
                  <SvgContainer name="playerLevelStar" />
                  <div class="numberScore absolute">
                    {{ player.level.toFixed(2) }}
                  </div>
                </div>
                <img
                  :class="{ rotate: player.rotating }"
                  :src="player.image"
                  alt=""
                />
              </div>
              <p
                v-if="player.type !== playerType.NONEXISTS"
                class="playerTitle font-semibold text-center capitalize"
              >
                {{ getPlayerName(player.name) }}
              </p>
              <p
                v-if="player.type !== playerType.NONEXISTS"
                class="playerSubtitle"
              >
                <span
                  v-if="
                    (isMatch &&
                      booking.paymentType?.code !== paymentType.IN_HOUSE) ||
                    (!isMatch && booking.paymentType?.code !== paymentType.FULL)
                  "
                  :class="{ 'error-message': player.printPriceUsingRed }"
                  class="ml-2"
                  >{{ getPrice(player) }}</span
                >
              </p>
              <div
                v-if="
                  player.customerId !== user.id &&
                  mode === playerMode.RESULTS &&
                  player.customerId !== undefined
                "
                class="absolute top-1 left-1"
                :class="{ 'text-red': player.ilike === true }"
                @click="iLike(player, $event)"
              >
                <img
                  v-if="player.ilike === false"
                  class="md:h-8"
                  src="../../assets/images/heart.svg"
                  alt=""
                />
                <img
                  v-if="player.ilike === true"
                  class="md:h-8"
                  src="../../assets/images/heartFull.svg"
                  alt=""
                />
                <!-- <p class="sm:hidden mr-5">I like</p> -->
              </div>
              <div
                v-if="player.showJoinMenu"
                class="w-full h-full containerPlayerIcons flex flex-col items-center absolute justify-center"
              >
                <div class="flex">
                  <input
                    class="md:mb-2 sm:mb-1"
                    type="button"
                    :value="$t('join_me')"
                    @click="joinMe(player, $event)"
                  />
                </div>
                <div v-if="isMatch === false">
                  <input
                    class="customBorder paymentOption cursor-pointer flex items-center p-6 justify-center"
                    type="button"
                    :value="$t('others')"
                    @click="joinOthers(player, $event)"
                  />
                </div>
              </div>
              <div
                v-if="player.showMenu"
                class="w-full h-full containerPlayerIcons flex flex-col items-center absolute justify-center"
              >
                <div class="flex">
                  <img
                    @click="removePlayer($event, player)"
                    v-if="removeAllowed(player)"
                    src="../../assets//images/icon1.png"
                    alt=""
                  />
                  <img
                    @click="startMoving($event, player)"
                    src="../../assets//images/icon2.png"
                    alt=""
                    v-if="moveAllowed(player)"
                  />
                </div>
                <div>
                  <img
                    @click="closeMenu($event, player)"
                    src="../../assets//images/icon3.png"
                    alt=""
                    v-if="removeAllowed(player) || moveAllowed(player)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="borderBoxPayment blankBoxRight"></div>
        </div>
        <div
          v-if="mode !== playerMode.RESULTS && enabled"
          ref="searchContainer"
          class="searchPlayers p-7 relative"
        >
          <div
            v-if="!isComplete && !isMatch"
            class="search-container relative flex"
          >
            <img
              class="seach-icon absolute"
              src="../../assets//images/magnifying-glass.svg"
              alt=""
            />
            <input
              v-if="notInTaykusMode == false"
              ref="playerToSearch"
              v-model="playerToSearch"
              class="reference-seach w-full"
              :placeholder="$t('search_player')"
              @input="searchPlayers()"
              @keyup="searchPlayers()"
              @focus="playerInputFocus()"
            />
            <img
              class="clear-icon absolute"
              src="../../assets/images/clear.svg"
              alt=""
              @click="clearSearch()"
            />
          </div>
          <div
            v-if="searchResults.length > 0"
            :class="{ paymentBox: searchResults !== undefined }"
            class="containerPlayers pr-6 md:pb-4"
          >
            <div
              v-for="searchResult in searchResults"
              :key="searchResult.customerId"
              class="flex items-center justify-between pr-12 pt-2 pb-2 cursor-pointer md:mb-2 relative"
              @click="
                assignPlayer(
                  searchResult.customerId,
                  searchResult.fullName,
                  searchResult.image
                )
              "
            >
              <div class="flex items-center sm:justify-center">
                <img class="mr-5" :src="searchResult.image" alt="" />
                <div class="mr-3">
                  <p class="sm:text-xs">
                    {{ searchResult.fullName }}
                  </p>
                  <!-- <p class="md:hidden playerSubtitle sm:text-xs">Friend Taykus</p> -->
                </div>
              </div>
              <p class="playerSubtitle sm:text-xs">{{ $t("taykus_player") }}</p>
            </div>
          </div>
          <div
            v-if="!isComplete && !isMatch"
            class="custom-gradient text-center text-md not-italic font-semibold mt-4 sm:text-base cursor-pointer"
            @click="notInTaykusClicked"
          >
            {{ $t("searching_for_a_non_Taker_player_question")
            }}{{ $t("click_here") }}
          </div>
          <div
            v-if="
              (playerAdded && payable) ||
              existAnyPlayerToPay ||
              bookingData.id === undefined
            "
            @click="goToPay()"
            class="flex justify-center text-center buttomPayMode mt-4 cursor-pointer sm:w-full"
          >
            {{ anyPaymentPending() ? $t("pay") : $t("text_continue") }}
          </div>
        </div>
      </div>
    </div>
    <Transition>
      <PopUpLight v-if="notInTaykusMode" @closed="notInTaykusMode = false">
        <div class="mt-8">
          <input
            id="notInTaykusInput"
            v-model="notInTaykusPlayerName"
            class="reference-seach w-full"
            :placeholder="$t('write_player_name')"
          />
          <br />
          <div
            @click="
              assignPlayer('', notInTaykusPlayerName, '');
              notInTaykusMode = false;
            "
            class="flex justify-center filledButton md:mt-8 sm:mt-4 cursor-pointer sm:w-full"
          >
            {{ $t("text_continue") }}
          </div>
        </div>
      </PopUpLight>
    </Transition>
    <div v-if="isSoccer()">
      <div
        v-if="canJoinSoccer()"
        @click="joinSoccer()"
        class="flex justify-center buttomPayMode md:mt-8 sm:mt-4 cursor-pointer absolute sm:w-full"
      >
        {{ $t("Join") }}
      </div>
      <div
        v-if="canRemoveSoccer()"
        @click="removeSoccerPlayer()"
        class="flex justify-center buttomPayMode md:mt-8 sm:mt-4 cursor-pointer absolute sm:w-full"
      >
        {{ $t("text_cancel") }}
      </div>
    </div>
    <Transition>
      <PopUpLight
        v-if="isMatch && showGenderLevel && !isLoading"
        @closed="closeGenderLevel"
        :customClass="'width-sm'"
        :showClose="false"
      >
        <UpdateUser
          :customSport="booking.sport"
          @closeGenderLevel="closeGenderLevel"
        />
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight v-if="showHasGender" :showClose="true" @closed="closeGender">
        <div class="mt-8">
          <UserGenderSelector @selectionChanged="saveGenderChanges" />
        </div>
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight
        v-if="showIframe"
        @closed="closeRedpadelIframe"
        :customClass="'width-lg'"
      >
        <div class="mt-8">
          <iframe
            class="iframe"
            title="redPadel login"
            frameborder="0"
            :src="iframeSrc"
          ></iframe>
        </div>
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight
        v-if="showRedpadelError"
        @closed="showRedpadelError = false"
        :customClass="'width-lg'"
      >
        <div class="mt-8">
          {{ $t("oops_technical_problems_try_again_in_a_few_minutes") }}
        </div>
      </PopUpLight>
    </Transition>
  </main>
</template>

<script type="ts">
import { CustomerApi } from "@/service/CustomerApi";
import { BookingApi } from "@/service/BookingApi";
import { customGlobalError } from "@/helpers/errorHelper";
import {
  BookingStatus,
  Category,
  NO_LEVEL,
  PadelSize,
  PaymentType,
  PlayersMode,
  PlayerType,
  Sport,
  PlayersNumber,
  LevelProvider,
} from "@/enum/constants";
import PopUpLight from "../popup/popupLight.vue";
import { Format } from "@/helpers/formatHelper";
import { AppsHelper } from "@/helpers/appsHelper";
import SvgContainer from "@/views/SvgContainer.vue";
import { hasLevel } from "@/helpers/bookingHelper";
import CustomerHelper from "@/helpers/customerHelper";
import UpdateUser from "@/components/match/updateUser.vue";
import { addThreeDotsText } from "@/helpers/dataHelper";
import { returnSportPlayers } from "@/helpers/bookingHelper";
import UserGenderSelector from "@/components/form/userGenderSelector.vue";
import store from "@/store";

export default {
  name: "addPlayers",
  emits: ["setUpCompleted"],
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
    mode: {
      type: Number,
      required: true,
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    PopUpLight,
    SvgContainer,
    UpdateUser,
    UserGenderSelector,
  },
  data() {
    return {
      players: [],
      movingPosition: null,
      playerToSearch: null,
      searchResults: [],
      notInTaykusMode: false,
      notInTaykusPlayerName: "",
      playerMode: PlayersMode,
      user: null,
      booking: null,
      isMatch: false,
      isComplete: false,
      size: null,
      playerType: PlayerType,
      existAnyPlayerToPay: false,
      playerAdded: false,
      enabled: true,
      emptyImage: "/img/whiteCircle.72a58138.svg",
      paymentType: PaymentType,
      bookingStatus: BookingStatus,
      NO_LEVEL: NO_LEVEL,
      showGenderLevel: false,
      isLoading: false,
      level: null,
      selectedPlayer: null,
      hasGender: undefined,
      payable: false,
      maxPlayers: 0,
      isIndividual: false,
      cssCourtBoxHeight: "360px",
      cssCourtMinHeight: "360px",
      cssCourtMinHeightMobile: "198px",
      levelProvider: undefined,
      showIframe: false,
      showRedpadelError: false,
      showHasGender: false,
      iframeSrc: undefined,
      socialRating: 0,
    };
  },
  watch: {
    cssCourtBoxHeight(newVal) {
      this.$el.style.setProperty("--box-height", newVal);
    },
    cssCourtMinHeight(newVal) {
      this.$el.style.setProperty("--min-height", newVal);
    },
    cssCourtMinHeightMobile(newVal) {
      this.$el.style.setProperty("--min-height-mobile", newVal);
    },
    active(newValue) {
      this.enabled = newValue;
    },
  },
  async created() {
    let user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
    }
    this.booking = this.bookingData;
    if (this.booking.sport.code !== undefined) {
      this.booking.sport = this.booking.sport.code;
    }

    if (
      this.booking.type !== undefined &&
      this.booking.type.code === Category.MATCH
    ) {
      this.isMatch = true;
    }

    if (this.booking.levelProvider) {
      this.levelProvider = this.booking.levelProvider.code;
    }
    this.size = this.booking.size;
    if (this.size !== undefined && this.size.code !== undefined) {
      this.size = this.size.code;
    }
    this.hasGender = await CustomerHelper.hasGender();
    if (!this.size) {
      if (this.booking.attributes) {
        this.size = this.booking.attributes.size;
      }
    }

    if (this.mode === PlayersMode.CANCELED) {
      this.enabled = false;
    }
    this.maxPlayers = returnSportPlayers(this.size);
    if (this.maxPlayers === PlayersNumber.INDIVIDUAL) {
      this.isIndividual = true;
      this.cssCourtBoxHeight = "170px";
      this.cssCourtMinHeight = "170px";
      this.cssCourtMinHeightMobile = "108px";
    }
    this.setUpPlayers();
    this.joinFromUrl();
    this.setUpExistAnyPlayerToPay();
  },
  methods: {
    hasLevel,
    async linkRedPadelAccount(linkLevelProvider) {
      try {
        await CustomerHelper.linkLevelProviderAccount(
          linkLevelProvider,
          LevelProvider.REDPADEL
        );
        this.level = this.socialRating;
        this.assignPlayer(this.user.id, this.user.fullName, this.user.image);
        this.selectedPlayer = null;
      } catch (error) {
        this.showRedpadelError = true;
      }
    },
    closeRedpadelIframe() {
      this.showIframe = false;
      window.removeEventListener("message", this.redPadelListener);
    },
    closeGender() {
      this.showHasGender = false;
    },
    async initRedPadelLevel() {
      if (!this.hasGender) {
        this.showHasGender = true;
        return false;
      }
      const response = await CustomerHelper.getProviderRedirectURL(
        LevelProvider.REDPADEL
      );
      if (response) {
        this.iframeSrc = response.redirectURL;
        this.showIframe = true;
        window.addEventListener("message", this.redPadelListener);
      }
    },
    async redPadelListener(event) {
      window.removeEventListener("message", this.redPadelListener);
      const resultData = event.data;
      this.showIframe = false;
      if (resultData.status) {
        if (resultData.socialRating) {
          this.socialRating = Number(resultData.socialRating);
        }
        const linkLevelProvider = {
          accountId: resultData.user_id,
        };
        await this.linkRedPadelAccount(linkLevelProvider);
      } else {
        this.showRedpadelError = true;
      }
    },
    async saveGenderChanges(gender) {
      const user = await CustomerHelper.updateGender(gender);
      if (user) {
        CustomerHelper.updateStorageUser(user);
        this.hasGender = true;
        this.joinMe(this.selectedPlayer, null);
      }
      this.showHasGender = false;
    },
    async initGenderLevel() {
      this.showHasGender = false;
      const hasLevel = await CustomerHelper.hasLevel(
        this.booking.sport,
        this.levelProvider
      );
      if (hasLevel) {
        this.level = await CustomerHelper.getSportLevel(
          this.booking.sport,
          this.levelProvider
        );
        return this.joinMe(this.selectedPlayer, null);
      }
      this.levelProvider === LevelProvider.PANDORA
        ? (this.showGenderLevel = true)
        : this.initRedPadelLevel();
    },
    async closeGenderLevel() {
      this.hasGender = true;
      this.level = await CustomerHelper.getSportLevel(
        this.booking.sport,
        this.levelProvider
      );
      this.joinMe(this.selectedPlayer, null);
      this.showGenderLevel = false;
    },
    setUpPlayers() {
      if (AppsHelper.isIOSWebView()) {
        this.sendMoveToIOs();
      }
      if (
        this.booking.players !== undefined &&
        this.booking.players.length > 0
      ) {
        this.players = [];
        this.booking.players.forEach((player) => {
          if (Format.IsNull(player.position)) {
            player.position = this.getPosition(player.team, player.side);
          }
          let image = player.image;
          if (Format.IsNull(image)) {
            image =
              player.type === undefined || player.type.code === PlayerType.EMPTY
                ? this.emptyImage
                : player.image;
          }
          /* Print player price in red when:
           1. If payment type is shared or in house
           2. Player is not paid
           3. Booking status in not cancelled
          */
          let printPriceUsingRed =
            !player.paid &&
            ((this.booking.paymentType?.code !== PaymentType.FULL &&
              this.booking.status?.code !== BookingStatus.CANCELED) ||
              (this.booking.paymentType?.code === PaymentType.FULL &&
                this.isMatch));
          this.players.push({
            customerId: player.customerId,
            customerOwnerId: player.customerOwnerId,
            deletable: player.deletable,
            editable: player.editable,
            insertable: player.insertable,
            image: image,
            originalImage: player.image,
            name: player.name,
            position: player.position,
            owner: player.owner,
            playerId: player.playerId,
            ilike: player.ilike,
            selected: false,
            paid: player.paid,
            payable: player.payable,
            showMenu: false,
            rotating: false,
            joinOthers: false,
            playerToSearch: "",
            type:
              player.type !== undefined ? player.type.code : PlayerType.EMPTY,
            amountData:
              player.amountData !== undefined ? player.amountData : null,
            level: player.level,
            printPriceUsingRed: printPriceUsingRed,
          });
        });
        this.fillEmptyPlayers();
        this.refreshPlayers();
        this.setUpIsComplete();
        return;
      }
      this.players.push({
        customerId: this.user.id,
        name: this.user.fullName,
        image: this.user.image,
        originalImage: this.user.image,
        position: 0,
        owner: true,
        selected: false,
        showMenu: false,
        rotating: false,
        joinOthers: false,
        paid: false,
        type: PlayerType.CUSTOMER,
      });

      this.fillEmptyPlayers();
    },
    fillEmptyPlayers() {
      if (this.players.length === this.maxPlayers) {
        return;
      }
      for (var i = this.players.length; i < this.maxPlayers; i++) {
        this.players.push({
          customerId: "",
          name: "",
          image: this.emptyImage,
          position: i,
          owner: false,
          selected: false,
          showMenu: false,
          rotating: false,
          joinOthers: false,
          playerToSearch: "",
          paid: false,
          type:
            this.size === PadelSize.INDIVIDUAL && i >= 2 // in individual fill positions 2 and 3 with nonexists
              ? PlayerType.NONEXISTS
              : PlayerType.EMPTY,
        });
      }
    },
    joinFromUrl() {
      if (
        this.mode === PlayersMode.JOIN &&
        this.$route.query.s !== undefined &&
        this.$route.query.t !== undefined
      ) {
        var exists = this.players.find((y) => y.customerId === this.user.id);
        if (exists !== undefined) {
          return;
        }
        try {
          const side = parseInt(this.$route.query.s);
          const team = parseInt(this.$route.query.t);
          const position = this.getPosition(team, side);
          let positionSelected = this.players.find(
            (y) => y.position === position
          );

          if (Format.IsNull(positionSelected)) {
            positionSelected = this.players.find(
              (y) => y.owner == false && Format.IsNull(y.name)
            );
          }

          if (positionSelected.type !== PlayerType.EMPTY) {
            return;
          }

          positionSelected.selected = true;
          this.assignPlayer(this.user.id, this.user.fullName, this.user.image);
        } catch {
          customGlobalError(this.$t("wrong_url"), "/");
        }
      }
    },
    setUpIsComplete() {
      this.isComplete =
        this.players.filter((y) => y.type === PlayerType.EMPTY).length === 0;
    },
    setUpExistAnyPlayerToPay() {
      this.existAnyPlayerToPay =
        this.players.filter((y) => this.playerShouldPay(y)).length > 0;
    },
    playerShouldPay(player) {
      return player.payable === true;
    },
    anyPaymentPending() {
      return (
        this.players.filter(
          (y) =>
            !y.owner &&
            y.type === (PlayerType.CUSTOMER || PlayerType.UNKNOWN) &&
            y.paid === false
        ).length > 0
      );
    },
    selectPlayer(player) {
      this.refreshPlayers();
      this.players.forEach((playerInArray) => {
        if (playerInArray.customerId !== player.customerId) {
          playerInArray.showMenu = false;
        }
      });
      if (player.type === PlayerType.NONEXISTS) {
        return;
      }
      if (this.movingPosition !== null) {
        this.swapPosition(player.position, this.movingPosition);
        this.refreshPlayers();
        this.movingPosition = null;
        return;
      }
      if (this.mode === this.playerMode.RESULTS && this.freeSpot(player)) {
        return;
      }
      player.showJoinMenu = false;
      player.selected = true;

      // occupied spot
      if (
        !this.freeSpot(player) &&
        (this.removeAllowed(player) || this.moveAllowed(player))
      ) {
        player.showMenu = true;
        return;
      }

      // empty spot
      if (this.mode === this.playerMode.JOIN) {
        if (!player.insertable) {
          return;
        }
        player.showJoinMenu = true;
        if (
          this.players.filter((y) => y.customerId === this.user.id).length !== 0
        ) {
          player.showJoinMenu = false;
          player.joinOthers = true;
        }
        if (player.joinOthers === false) {
          player.selected = false;
          return;
        }
      }

      const targetElement = this.$refs.playerToSearch;
      if (targetElement) {
        targetElement.focus();
      }
    },
    playerInputFocus() {
      if (AppsHelper.isSafariOrIosWebView()) {
        return;
      }
      const targetElement = this.$refs.searchContainer;
      if (targetElement) {
        targetElement.classList.add("search-mobile");
      }
      setTimeout(() => {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }, 600);
    },
    async joinMe(player, e) {
      this.selectedPlayer = player; //selectedPlayer only is for call joinMe with the same player from other function
      let canAddMe = true;
      this.hasGender = await CustomerHelper.hasGender();
      if ((!this.hasGender || !this.level) && this.isMatch) {
        canAddMe = await this.initGenderLevel();
      }
      if (!Format.IsNull(e)) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (canAddMe) {
        //stops joinme until gender and level checks are ok
        player.joinOthers = false;
        player.selected = true;
        this.assignPlayer(this.user.id, this.user.fullName, this.user.image);
        this.selectedPlayer = null;
      }
    },
    joinOthers(player, e) {
      e.preventDefault();
      e.stopPropagation();
      player.joinOthers = true;
      this.selectPlayer(player);
    },
    async searchPlayers() {
      this.playerToSearch = this.$refs.playerToSearch.value;
      if (this.playerToSearch !== null && this.playerToSearch.length >= 3) {
        var res = await CustomerApi.findByNameContaining(
          this.playerToSearch,
          0,
          25
        );
        this.searchResults = res.data;
      }
    },
    clearSearch() {
      this.playerToSearch = "";
      this.searchResults = [];
      const targetElement = document.getElementById("searchContainer");
      if (targetElement) {
        targetElement.classList.remove("search-mobile");
      }
      setTimeout(() => {
        document.body.scrollTop = 0;
      }, 300);
    },
    async assignPlayer(customerId, name, image) {
      if (image == "") {
        image = this.emptyImage;
      }
      this.notInTaykusPlayerName = ""; //clean input
      if (Format.IsNull(name)) {
        return;
      }
      const searchContainer = this.$refs.searchContainer;
      if (searchContainer) {
        searchContainer.classList.remove("search-mobile");
      }

      const targetElement = document.getElementsByTagName("body")[0];
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }

      var exists = this.players.find((y) => y.customerId === customerId);
      if (exists !== undefined && customerId !== "") {
        customGlobalError(this.$t("this_player_is_already_in_game"));
        return;
      }

      let positionSelected = this.players.find((y) => y.selected === true);
      if (Format.IsNull(positionSelected)) {
        positionSelected = this.players.find(
          (y) => y.owner == false && Format.IsNull(y.name)
        );
      }
      if (positionSelected !== undefined && !positionSelected.owner) {
        if (this.mode === PlayersMode.CREATION) {
          positionSelected.name = name;
          positionSelected.customerId = customerId;
          positionSelected.selected = false;
          positionSelected.showJoinMenu = false;
          positionSelected.image = image;
          positionSelected.originalImage = image;
        }

        if (this.mode === PlayersMode.JOIN) {
          const player = {
            customerId: customerId,
            name: name,
          };
          store.commit("setLoading", true);
          const response = await BookingApi.addPlayer(
            player,
            this.booking.id,
            positionSelected.playerId
          );
          store.commit("setLoading", false);
          this.booking.players = response.data.players;
          if(this.isMatch){
            this.booking.minLevel = response.data.minLevel
            this.booking.maxLevel = response.data.maxLevel
          }
          let addedUser = this.booking.players.find(
            (y) => y.customerId === customerId
          );
          this.setUpPlayers();
          if (addedUser) {
            this.payable = addedUser.payable;
          }
        }
        this.searchResults = [];
        this.playerToSearch = "";
        this.playerAdded = true;
      }
    },
    async removePlayer(e, player) {
      if (e !== null) {
        e.stopPropagation();
      }
      if (this.mode === PlayersMode.JOIN) {
        store.commit("setLoading", true);
        const response = await BookingApi.deletePlayer(
          this.booking.id,
          player.playerId
        );
        store.commit("setLoading", false);
        if(this.isMatch){
          this.booking.minLevel = response.data.minLevel
          this.booking.maxLevel = response.data.maxLevel
        }
        this.booking.players = response.data.players;
        this.setUpPlayers();
      }
      player.name = "";
      player.customerId = "";
      player.showMenu = false;
      if (this.isMatch) {
        this.playerAdded = false;
      }
    },
    startMoving(e, player) {
      e.stopPropagation();
      player.showMenu = false;
      player.showJoinMenu = false;
      this.movingPosition = player.position;
      this.players.forEach((obj) => {
        // Only rotate players where player can be moved
        if (this.moveAllowed(obj)) {
          obj.rotating = true;
          obj.image = this.emptyImage;
        }
      });
      player.rotating = false;
    },
    cancelMoving() {
      if (this.movingPosition !== null) {
        this.movingPosition = null;
      }
      this.refreshPlayers();
    },
    async swapPosition(dest, orig) {
      let playerDest = this.players.find((y) => y.position === dest);
      let playerOrig = this.players.find((y) => y.position === orig);
      if (!this.swapPositionAllowed(playerOrig, playerDest)) {
        customGlobalError(this.$t("this_change_of_position_is_not_allowed"));
        return;
      }
      if (this.mode === PlayersMode.JOIN || this.mode == PlayersMode.RESULTS) {
        if (playerOrig.playerId != playerDest.playerId) {
          const response = await BookingApi.movePlayer(
            this.booking.id,
            playerOrig.playerId,
            playerDest.playerId
          );
          this.booking.players = response.data.players;
          this.setUpPlayers();
        }
        return;
      }
      playerDest.position = orig;
      playerOrig.position = dest;
    },
    notInTaykusClicked() {
      this.notInTaykusMode = true;
      this.clearSearch();
    },
    goToPay() {
      let query = this.booking;
      query.players = this.players
        .filter((y) => y.type !== PlayerType.NONEXISTS)
        .map(
          ({
            customerId,
            name,
            position,
            owner,
            playerId,
            customerOwnerId,
            paid,
            deletable,
            editable,
            payable,
            image,
          }) => ({
            customerId,
            name,
            position,
            owner,
            playerId,
            customerOwnerId,
            paid,
            deletable,
            editable,
            payable,
            image,
          })
        );
      if (this.mode === PlayersMode.JOIN) {
        if (!this.isMatch) {
          query.players = query.players.filter(
            (y) => y.owner === false && this.playerShouldPay(y)
          );
        }
        if (this.isMatch) {
          query.players = query.players.filter(
            (y) => y.paid === false && y.customerId === this.user.id
          );
        }
      }
      this.$emit("setUpCompleted", query);
    },
    getPlayerName(name) {
      if (Format.IsNull(name)) {
        if (
          this.isMatch &&
          this.players.filter((y) => y.customerId === this.user.id).length !== 0
        ) {
          return this.$t("unknow_player");
        }
        if (this.mode !== PlayersMode.RESULTS && this.enabled) {
          return this.$t("click_to_add");
        }
        return this.$t("unknow_player");
      }
      return addThreeDotsText(name);
    },
    getPrice(player) {
      if (
        (player.type === PlayerType.CUSTOMER ||
          player.type === PlayerType.UNKNOWN) &&
        player.amountData !== undefined
      ) {
        return this.getFormatedCurrency(player.amountData);
      }
    },
    getFormatedCurrency(price) {
      return Format.formatCurrency(
        price.grossAmount,
        price.currencyCode,
        price.locale
      );
    },
    closeMenu(e, player) {
      e.stopPropagation();
      player.showMenu = false;
      player.showJoinMenu = false;

      this.players.forEach((obj) => {
        obj.selected = false;
        obj.showMenu = false;
        obj.showJoinMenu = false;
      });
    },
    getPlayers() {
      return this.players.sort((a, b) => a.position - b.position);
    },
    async iLike(player, e) {
      e.preventDefault();
      e.stopPropagation();
      if (Format.IsNull(player.customerId)) {
        return;
      }

      player.ilike =
        Format.IsNull(player.ilike) || player.ilike === false ? true : false;
      if (player.ilike) {
        await CustomerApi.createCustomerLike(player.customerId);
        return;
      }
      await CustomerApi.deleteCustomerLikeByTargetHash(player.customerId);
    },
    freeSpot(player) {
      switch (this.mode) {
        case PlayersMode.CREATION:
          return Format.IsNull(player.name);
        case PlayersMode.JOIN:
          return player.insertable;
        case PlayersMode.RESULTS:
          return player.insertable;
      }
    },
    removeAllowed(player) {
      switch (this.mode) {
        case PlayersMode.CREATION:
          return player.owner === false;
        case PlayersMode.JOIN:
          return player.deletable;
        case PlayersMode.RESULTS:
          return false;
      }
    },
    moveAllowed(player) {
      let emptyPosition = false;
      // Looking for any empty position
      this.players.forEach((obj) => {
        if (obj.type == PlayerType.EMPTY) {
          emptyPosition = true;
        }
      });
      switch (this.mode) {
        case PlayersMode.CREATION:
          return true;
        case PlayersMode.JOIN:
          if (this.isMatch && !emptyPosition) {
            return false;
          }
          return player.editable;
        case PlayersMode.RESULTS:
          return player.editable;
      }
    },
    swapPositionAllowed(playerOrig, playerDest) {
      switch (this.mode) {
        case PlayersMode.CREATION:
          return true;
        case PlayersMode.JOIN:
          return (
            playerOrig.editable ||
            playerDest.editable ||
            Format.IsNull(playerDest.name)
          );
        case PlayersMode.RESULTS:
          return true;
      }
    },
    getPosition(team, side) {
      if (team != null && side != null) {
        if (team === 0 && side === 0) {
          return 1;
        } else if (team === 0 && side === 1) {
          return 3;
        } else if (team === 1 && side === 0) {
          return 2;
        } else if (team === 1 && side === 1) {
          return 4;
        }
      }
    },
    isSoccer() {
      if (this.booking === null) {
        return false;
      }
      return this.booking.sport === Sport.SOCCER;
    },
    canJoinSoccer() {
      return (
        this.mode == PlayersMode.JOIN &&
        this.players.find((y) => y.customerId === this.user.id) == undefined
      );
    },
    canRemoveSoccer() {
      return (
        this.mode == PlayersMode.JOIN &&
        this.players.find(
          (y) => y.customerId === this.user.id && y.owner !== true
        ) !== undefined
      );
    },
    joinSoccer() {
      this.assignPlayer(this.user.id, this.user.fullName);
      this.goToPay();
    },
    removeSoccerPlayer() {
      const p = this.players.find((y) => y.customerId === this.user.id);
      this.removePlayer(null, p);
    },
    refreshPlayers() {
      this.players.forEach((obj) => {
        if (Format.IsNull(obj.customerId)) {
          obj.image = this.emptyImage;
        } else if (!Format.IsNull(obj.originalImage)) {
          obj.image = obj.originalImage;
        }
        obj.rotating = false;
        obj.selected = false;
        obj.showMenu = false;
        obj.showJoinMenu = false;
      });
    },
    sendMoveToIOs() {
      const jsonData = {
        matchUpdated: "true",
      };
      if (AppsHelper.sendToIOS(window, jsonData)) {
        return;
      }
    },
  },
};
</script>
<style scoped>
.scoreUsers {
  top: -8px;
  font-size: 10px;
  right: -14px;
  font-weight: bold;
  color: black;
}

.numberScore {
  top: 10px;
  left: 5px;
}

.error_color {
  color: #f472b6;
}

.borderTest {
  border-right: 4px solid !important;
}

.search-container .clear-icon {
  z-index: 0;
  top: 8.3px;
  right: 10px;
  cursor: pointer;
  height: 27px;
}

.paymentBox {
  border-radius: 24px;
  background: var(--background-color-button);
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  z-index: 9999;
  position: relative;
}

.locatePlayers {
  width: 50%;
  /* height: 592px; */
  height: fit-content;
  flex-shrink: 0;
}

.searchPlayers {
  width: 50%;
  min-width: 462px;
  /* height: 627px; */
  flex-shrink: 0;
}

@media (min-width: 1200px) {
  .locatePlayers {
    min-width: 612px;
  }

  .searchPlayers {
    min-width: 462px;
  }
}

.borderBoxPayment {
  border: 1px solid rgba(255, 255, 255, 0.11);
}

.wrapperBoxPayment {
  width: 680px;
}

.boxPayment {
  width: 50%;
  cursor: pointer;
  background: #0458e2ad;
  border: 1px solid var(--main-color);
}

.blankBoxRight {
  width: 20%;
  height: var(--box-height, 360px);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background: #0458e2ad;
  border: 1px solid var(--main-color);
}

.blankBoxLeft {
  width: 20%;
  height: var(--box-height, 360px);
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background: #0458e2ad;
  border: 1px solid var(--main-color);
}

.resetBorder {
  border-radius: 32px;
  background-color: aqua;
  width: 327px;
  padding: 1px;
  background-image: linear-gradient(to right, var(--color1), var(--color2));
}

.reset-button {
  background: var(--background-color-box);
  font-size: 16px;
  cursor: pointer;
  border-radius: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
}

.imageBoxPlayers {
  width: 64px;
  height: 64px;
}

.imageBoxPlayers img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.selectPlayerBorder .imageBoxPlayers::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  height: 64px;
  background-color: var(--color2);
  background-size: 100%;
  background-image: url("../../assets//images/whiteCircle.svg");
}

.selectPlayerBorder .imageBoxPlayers::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-image: url("../../assets//images/userAddWhite.svg");
}

.selectPlayerBorder {
  border-image: linear-gradient(to right, var(--color1), var(--color2));
  border-image-slice: 1;
}

.reference-seach {
  box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  height: 44px;
  padding-left: 46px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid;
  border-radius: 24px;
  border-bottom-color: #264b67;
  border-left-color: #264b67;
  border-right-color: #076ae3;
  border-top-color: #264b67;
}

.containerPlayers {
  max-height: 585px;
  overflow: scroll;
  overflow-x: hidden;
}

.containerPlayers::-webkit-scrollbar {
  width: 4px;
}

.containerPlayers::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.11);
  border-radius: 10px;
}

.containerPlayers::-webkit-scrollbar-thumb {
  background-color: #3d5c8f;
  border-radius: 10px;
}

.containerPlayers img {
  width: 48px;
  height: 48px;
}

.referenceSelect {
  background: linear-gradient(
    107deg,
    rgba(6, 27, 46, 0) 0%,
    rgba(7, 106, 227, 0.13) 100%
  );
  border-radius: 16px;
}

.referenceSelect::after {
  content: "";
  position: absolute;
  top: 21px;
  right: 0;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url("../../assets//images/blueCheck.png");
}

.containerPlayerIcons {
  backdrop-filter: blur(3px);
}

.containerPlayerIcons img:hover {
  transform: scale(1.1);
  transition: 0.4s;
}

.wrapperPlayers {
  flex-direction: column;
  gap: 24px;
}

@media (max-width: 1200px) {
  .searchPlayers {
    width: 100%;
  }

  .locatePlayers {
    width: 100%;
    margin: 0;
  }
}

.playersContainer {
  position: relative;
  min-width: 250px;
  min-height: var(--min-height, 170px);
  width: 100%;
}

@media (max-width: 767px) {
  .searchPlayers {
    width: 100%;
    height: auto;
  }

  .playersContainer {
    min-height: var(--min-height-mobile, 198px);
  }

  .locatePlayers {
    background-color: transparent;
    height: auto;
  }

  .wrapperBoxPayment {
    width: 100%;
    height: auto;
  }

  .boxPayment {
    width: 50%;
  }

  .blankBoxRight {
    width: 15%;
    height: auto;
  }

  .blankBoxLeft {
    width: 15%;
    height: auto;
  }

  .imageBoxPlayers {
    width: 44px;
    height: 44px;
  }

  .selectPlayerBorder .imageBoxPlayers::before {
    width: 44px;
    height: 44px;
  }

  .selectPlayerBorder .imageBoxPlayers::after {
    width: 20px;
    height: 20px;
    background-size: 100%;
  }

  .borderBoxPayment .playerSubtitle,
  .borderBoxPayment .playerTitle {
    font-size: 13px;
  }

  .searchPlayers {
    min-width: 100%;
  }

  .containerPlayers img {
    width: 38px;
    height: 38px;
  }

  .containerPlayers {
    max-height: 235px;
  }

  .borderBoxPayment .playerSubtitle,
  .borderBoxPayment .playerTitle {
    font-size: 10px;
  }
}

.buttomPayMode {
  border-radius: 32px;
  padding: 14px 0;
  background-image: linear-gradient(to right, var(--color1), var(--color2));
  margin-left: auto;
  margin-right: auto;
  width: 86%;
}

@media (min-width: 767px) {
  .buttomPayMode {
    border-radius: 32px;
    width: 327px;
    padding: 14px 0;
    background-image: linear-gradient(to right, var(--color1), var(--color2));
    margin-left: auto;
    margin-right: auto;
    left: 49%;
  }
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.borderBoxPayment input {
  width: 100px;
  padding: 8px;
  font-size: 12px;
}

.customBorder {
  background: linear-gradient(
        var(--background-color-box),
        var(--background-color-box)
      )
      padding-box,
    linear-gradient(60deg, var(--color1), var(--color2)) border-box;
  border: 1px solid transparent;
}
</style>